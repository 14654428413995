// #######################################
// Mixins
// #######################################
// Mixins: Selector
@mixin not-last {
	&:not(:last-child) {
		@content;
	}
}
@mixin not-first {
	&:not(:first-child) {
		@content;
	}
}
// Mixins: Width
@mixin r($width) {
	@media only screen and (max-width: #{$width}px) {
		@content;
	}
}
@mixin rmin($width) {
	@media only screen and (min-width: #{$width}px) {
		@content;
	}
}
// Mixins: Height
@mixin rh($height) {
	@media only screen and (max-height: #{$height}px) {
		@content;
	}
}
@mixin rhmin($height) {
	@media only screen and (min-height: #{$height}px) {
		@content;
	}
}
// Mixins: Text Overflow
@mixin text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
// Mixins: Cover Div
@mixin coverdiv {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
// Mixins: Vertical Center
@mixin vcenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
// Mixins: Cover
@mixin cover {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
}
// Mixins: retina, also available as +hdpi
@mixin retina {

	@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min-device-pixel-ratio: 2) {
		@content;
	}
}
// Mixins: font-family
@mixin font($alias, $name) {
	@font-face {
		font-family: $alias;
		src: url("fonts/#{$name}.woff2") format("woff2"),
		url("fonts/#{$name}.woff") format("woff");
		font-weight: normal;
		font-style: normal;
	}
}
// Mixins: Placeholder
@mixin placeholder {
	&::placeholder {
		@content;
	}
}
// Mixins: theme
@mixin dark {
	body.dark & {
		@content;
	}
}

// Mixins: set font inter
@mixin inter {
    font-family: 'Inter', sans-serif;
}

// #######################################
// Helpers
// #######################################
@function img-url($image){
	@return url('./images/#{$image}');
}

@mixin image($image) {
	background-image: img-url($image);
}

@mixin smooth-scroll {
	-webkit-overflow-scrolling: touch;
}

@mixin smooth-font {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// #######################################
// Resets
// #######################################
@import './helpers/reset';

// #######################################
// Media Queries
// #######################################
@import './helpers/media-queries';

// #######################################
// Variables
// #######################################
:root {
	/* Primary colors */
	--base-primary-1: #2A85FF; // p1
	--base-primary-2: #83BF6E; // p2
	--base-primary-3: #FF6A55; // p3
	--base-primary-4: #8E59FF; // p4

	/* Secondary colors */
	--base-secondary-1: #FFBC99; // s1
	--base-secondary-2: #CABDFF; // s2
	--base-secondary-3: #B1E5FC; // s3
	--base-secondary-4: #B5E4CA; // s4
	--base-secondary-5: #FFD88D; // s5

	/* Neutral colors */
	--contrast-white: #FFFFFF; // n
	--contrast-light-1: #FCFCFC; // n1
	--contrast-light-2: #F4F4F4; // n2
	--contrast-light-3: #EFEFEF; // n3
	--contrast-gray-1: #6F767E; // n4
	--contrast-dark-1: #33383F; // n5
	--contrast-dark-2: #272B30; // n6
	--contrast-dark-3: #1A1D1F; // n7
	--contrast-dark-4: #111315; // n8

	/* Shades */
	--shades-gray-1: #9A9FA5;
	--shades-gray-2: #6F767E;
	--shades-gray-3: rgba(111, 118, 126, 0.4);
	--shades-dark-1: rgba(17, 19, 21, 0.5);
}

// #######################################
// Plugins
// #######################################
// Jika memiliki plugin dan bersifat global bisa diimport disini

// #######################################
// General
// #######################################
body {
	min-width: 320px;
	background: var(--contrast-light-2);
	@include inter;
	font-size: 16px;
	// font-weight: 600;
	line-height: 1.6;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: -.01em;
	color: var(--contrast-dark-3);
}

blockquote{
	border-left: 4px solid var(--contrast-gray-1);
	padding-left: 24px;
	font-size: 18px;
}

ol{
	list-style-type: decimal;
	padding-left: 24px;
}
ul{
	list-style-type: disc;
	padding-left: 24px;
}

// #######################################
// Modificators
// #######################################
// Modificators: Text Styles
.text-style{
	&-strikethrough{
		text-decoration: line-through;
	}
	&-italic{
		font-style: italic;
	}
	&-muted{
		opacity: .6;
	}

	&-uppercase{
		text-transform: uppercase;
	}

	&-nowrap{
		white-space: nowrap;
	}

	&-link{
		color: var(--base-primary-1);
		text-decoration: underline;
	}

	&-quote{
		border-left: 4px solid var(--contrast-gray-1);
		padding-left: 24px;
		font-size: 16px;
	}

	&-2lines{
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&-3lines{
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}
// Modificators: Text Align
.text {
	&-align-right {
		text-align: right;
	}
	&-align-center {
		text-align: center;
	}
	&-align-left{
		text-align: left;
	}
}
.m-auto {
	margin: auto;
}
.ml-auto {
	margin-left: auto;
}
.mr-auto {
	margin-right: auto;
}
.align {
	&-baseline {
		align-items: baseline;
	}
}
// Modificators: Visibility
.desktop {
	&-hide {
		@include mq-medium {
			display: none !important;
		}
	}
	&-show {
		display: none !important;

		@include mq-medium {
			display: block !important;
		}
	}
}
.tablet {
	&-hide {
		@include mq-tablet {
			display: none !important;
		}
	}
	&-show {
		display: none !important;

		@include mq-tablet {
			display: block !important;
		}
	}

}
.mobile {
	&-hide {
		@include mq-tablet-small {
			display: none !important;
		}
	}
	&-show {
		display: none !important;

		@include mq-tablet-small {
			display: block !important;
		}
	}
}

// #######################################
// Global Classes
// #######################################
// Global Classes: Container
.padding-global{
	padding: 0 32px;
	width: 100%;

	@include mq-tablet{
		padding: 0 16px;
	}
}
.container{
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
}

// Global Classes: Flex Layout
.flex-layout{
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0 -8px;

	[class*="flex-col-"]{
		padding-left: 8px;
		padding-right: 8px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.flex-col{
		&-1{
			width: 8.33%;
		}
		&-2{
			width: 16.66%;
		}
		&-3{
			width: 25%;
		}
		&-4{
			width: 33.33%;
		}
		&-5{
			width: 41.66%;
		}
		&-6{
			width: 50%;
		}
		&-7{
			width: 58.333333333333336%;
		}
		&-8{
			width: 66.66666666666667%;
		}
		&-9{
			width: 75%;
		}
		&-10{
			width: 83.33%;
		}
		&-11{
			width: 91.66%;
		}
		&-12{
			width: 100%;
		}
	}
}

// Global Classes: Spacer
$spacer-heights: 8, 16, 24, 32, 48, 64, 80, 96, 128;

.spacer{
	@apply w-full;

	@each $height in $spacer-heights {
		$height-with-px: $height + px; // Tambahkan 'px' ke tinggi

		&-#{$height} {
			height: $height-with-px; // Gunakan nilai dengan 'px' dalam properti
		}
	}
}

// Global Classes: Grid
.grid-layout{
	display: grid;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	align-items: stretch;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 24px;
	grid-template-rows: auto;

	&.is-8-cols{
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}

	&.is-7-cols{
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}

	&.is-6-cols{
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	}

	&.is-5-cols{
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}

	&.is-4-cols{
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	&.is-3-cols{
		grid-template-columns: 1fr 1fr 1fr;
	}

	&.is-2-cols{
		grid-template-columns: 1fr 1fr;
	}

	&.is-2-1-cols{
		grid-template-columns: 2fr 1fr;
	}

	&.is-1-2-cols{
		grid-template-columns: 1fr 2fr;
	}

	&.is-1-col{
		grid-template-columns: 1fr;
	}
}

// Global Classes: Headers
.h1, .h2, .h3, .h4 {
    @include inter;
    font-weight: 600;
}
.h1 {
    font-size: 64px;
    line-height: 1;
    letter-spacing: -0.03em;
}
.h2 {
    font-size: 48px;
    line-height: 1;
    letter-spacing: -0.03em;
}
.h3 {
    font-size: 40px;
    line-height: calc(48 / 40);
    letter-spacing: -0.02em;
    @include mq-tablet-small() {
        font-size: 32px;
        line-height: calc(40 / 32);
        letter-spacing: -0.03em;
    }
}
.h4 {
    font-size: 32px;
    line-height: calc(40 / 32);
    letter-spacing: -0.03em;
}

// Global Classes: Buttons
[class^="button"] {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 48px;
	padding: 0 20px;
	background: var(--base-primary-1);
	border-radius: 12px;
	text-align: center;
	font-weight: 600;
	color: var(--contrast-light-1);
	transition: all .2s;

	&:hover {
		background: darken(#2A85FF, 10);
	}
	&.disabled {
		opacity: .5;
		pointer-events: none;
	}
	svg {
		fill: var(--contrast-light-1);
		transition: all .2s;
	}
	&:not([class^="button-square"]) {
		svg {
			&:first-child {
				margin-right: 8px;
			}
			&:last-child {
				margin-left: 8px;
			}
		}
	}
}

.button-stroke,
.button-stroke-red {
	background: none;
	box-shadow: 0 0 0 2px var(--contrast-light-3) inset;
}

.button-stroke {
	color: var(--contrast-dark-3);
	svg {
		fill: var(--contrast-gray-1);
	}

	&:hover {
		background: none;
		box-shadow: 0 0 0 2px var(--contrast-dark-3) inset;
		svg {
			fill: var(--contrast-dark-3);
		}
	}

	&.active {
		background: var(--contrast-dark-2);
		box-shadow: 0 0 0 2px var(--contrast-dark-2) inset;
		svg {
			fill: var(--contrast-dark-3);
		}
	}
}

.button-stroke-red {
	color: var(--base-primary-3);
	svg {
		fill: var(--base-primary-3);
	}

	&:hover,
	&.active {
		background: var(--base-primary-3);
		box-shadow: 0 0 0 2px var(--base-primary-3) inset;
		color: var(--contrast-light-1);
		svg {
			fill: var(--contrast-light-1);
		}
	}
}

.button-white {
	box-shadow: 0 0 0 2px var(--contrast-light-3) inset;
	background: var(--contrast-light-1);
	color: var(--contrast-dark-3);
	svg {
		fill: var(--contrast-dark-3);
	}

	&:hover,
	&.active {
		box-shadow: 0 0 0 2px var(--contrast-dark-3) inset;
		background: var(--contrast-white);
		color: var(--contrast-dark-3);

		svg {
			fill: var(--contrast-dark-3);
		}
	}
}

.button-small {
	height: 40px;
	padding: 0 16px;
	border-radius: 8px;
	font-size: 13px;
}

.button-square-stroke {
	flex: 0 0 48px;
	width: 48px;
	height: 48px;
	padding: 0;
	background: none;
	box-shadow: 0 0 0 2px var(--contrast-light-3) inset;
	transition: all .2s;
	svg {
		fill: var(--contrast-gray-1);
	}

	&:hover {
		background: var(--base-primary-1);
		box-shadow: 0 0 0 2px var(--base-primary-1) inset;
		svg {
			fill: var(--contrast-light-1);
		}
	}
}

.button-square-stroke.button-small {
	flex: 0 0 40px;
	width: 40px;
	height: 40px;
}

// Global Classes: Label
[class^="label"] {
    display: inline-block;
    padding: 0 8px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
}

.label-green {
    background: #EAFAE5;
    color: var(--base-primary-2);
    @include dark {
        background: rgba(#83BF6E, .15);
    }
}

.label-green-dark {
    background: var(--base-secondary-4);
    color: var(--contrast-dark-3);
}

.label-red {
    background: #FFE7E4;
    color: var(--base-primary-3);
    @include dark {
        background: rgba(#FF6A55, .15);
    }
}

.label-red-dark {
    background: var(--base-secondary-1);
    color: var(--contrast-light-1);
}

.label-purple {
    background: #CABDFF;
    color: var(--base-primary-4);
}

.label-blue {
    background: var(--base-secondary-3);
    color: var(--base-primary-1);
}

.label-yellow {
    background: var(--base-seoncary-5);
    color: var(--contrast-dark-3);
}

// Global Classes: Text & Background Colors
$color-properties: (
	base-primary-1: ( color: var(--base-primary-1) ),
	base-primary-2: ( color: var(--base-primary-2) ),
	base-primary-3: ( color: var(--base-primary-3) ),
	base-primary-4: ( color: var(--base-primary-4) ),

	base-secondary-1: ( color: var(--base-secondary-1) ),
	base-secondary-2: ( color: var(--base-secondary-2) ),
	base-secondary-3: ( color: var(--base-secondary-3) ),
	base-secondary-4: ( color: var(--base-secondary-4) ),

	contrast-white: ( color: var(--contrast-white) ),
	contrast-light-1: ( color: var(--contrast-light-1) ),
	contrast-light-2: ( color: var(--contrast-light-2) ),
	contrast-light-3: ( color: var(--contrast-light-3) ),
	contrast-gray-1: ( color: var(--contrast-gray-1) ),
	contrast-dark-1: ( color: var(--contrast-dark-1) ),
	contrast-dark-2: ( color: var(--contrast-dark-2) ),
	contrast-dark-3: ( color: var(--contrast-dark-3) ),
	contrast-dark-4: ( color: var(--contrast-dark-4) ),

	shades-gray-1: ( color: var(--shades-gray-1) ),
	shades-gray-2: ( color: var(--shades-gray-2) ),
	shades-gray-3: ( color: var(--shades-gray-3) ),
	shades-dark-1: ( color: var(--shades-dark-1) ),
);

@each $color, $props in $color-properties {
	.text-color-#{$color} {
		color: map-get($props, color);
	}
	.background-color-#{$color} {
		background-color: map-get($props, color);
	}
}


// Global Classes: Font Weight
$weight-values: (
	400,
	500,
	600
);

.font-weight {
	@each $weight in $weight-values {
		&-#{$weight} {
			font-weight: $weight !important;
		}
	}
}

// Global Classes: Font Size
$font-size-values: (
	24px,
	18px
);
.font-size {
	@each $fontsize in $font-size-values {
		&-#{$fontsize} {
			font-size: $fontsize;
		}
	}
}

// Global Classes: Display
$display-dom-values: (
	block,
	inline-block,
	flex,
	inline-flex
);
.display {
	@each $display_prop in $display-dom-values {
		&-#{$display_prop} {
			display: $display_prop
		}
	}
}

// #######################################
// Components
// #######################################

// Components: Page

// Components: Sidebar

// Components: Card

// Components: Header

// Components: Form

// Components: Checkbox

// Components: Radio

// Components: Control

// Components: Switch

// Components: Field

// Components: Loader
.loader {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    font-size: 4px;
    animation: loader 1.1s infinite ease;
    text-indent: -9999em;
    transform: translateZ(0);
}

@keyframes loader {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #777e90, 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.5), -1.8em -1.8em 0 0em rgba(119,126,144, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.7), 1.8em -1.8em 0 0em #777e90, 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.5), 1.8em -1.8em 0 0em rgba(119,126,144, 0.7), 2.5em 0em 0 0em #777e90, 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.5), 2.5em 0em 0 0em rgba(119,126,144, 0.7), 1.75em 1.75em 0 0em #777e90, 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.5), 1.75em 1.75em 0 0em rgba(119,126,144, 0.7), 0em 2.5em 0 0em #777e90, -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.5), 0em 2.5em 0 0em rgba(119,126,144, 0.7), -1.8em 1.8em 0 0em #777e90, -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.5), -1.8em 1.8em 0 0em rgba(119,126,144, 0.7), -2.6em 0em 0 0em #777e90, -1.8em -1.8em 0 0em rgba(119,126,144, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.5), -2.6em 0em 0 0em rgba(119,126,144, 0.7), -1.8em -1.8em 0 0em #777e90;
    }
}
